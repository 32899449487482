<template>
  <div class="main">
    <div class="top">
      <div
        v-for="i in 3"
        :key="`mini-point-${i}`"
        class="mini-point"
      />
    </div>
    <div class="content">
      <div class="img-box">
        <div class="img">
          <img
            v-if="(type === 'templateUrl' || type === 'uploadPoster')&& isShowUrl"
            :src="isShowUrl"
          >
          <div
            v-else
            class="play-btn"
          >
            <img src="../img/视频.svg">
          </div>
        </div>
        <div class="list-box">
          <ul
            class="list"
            :class="!baseForm.liveType && 'bg-color'"
          >
            <li class="item" />
            <li class="item" />
          </ul>
          <ul class="list-content">
            <li
              v-for="i in 6"
              :key="`list-item-${i}`"
              class="item"
            >
              <div
                class="logo pre-tooltip"
                :class="{
                  'round': !(projects),
                  'party': projects,
                  'deep-color': isListDeep && i === 1,
                }"
              >
                <template v-if="isListDeep && (type === 'guests' || type === 'invites') && i === 1">
                  <div class="tooltip" />
                  <div class="tooltip-box guest">
                    <logo-panel
                      :info="cardInfoData"
                      class="avatar"
                    >
                      <img
                        slot="logo"
                        class="weitu"
                        src="../img/位图.png"
                      >
                    </logo-panel>
                    <span>嘉宾姓名</span>
                  </div>
                </template>
              </div>
              <div
                class="name pre-tooltip"
                :class="{
                  'deep-color': isListDeep && i === 1,
                }"
              >
                <template v-if="isListDeep && (projects) && i === 1">
                  <div class="tooltip" />
                  <div class="tooltip-box project">
                    <div class="project-img">
                      <img src="../img/logo.png">
                    </div>
                    <div class="project-info">
                      <div class="project-name">
                        项目名称
                      </div>
                      <div class="project-type">
                        项目简介
                      </div>
                      <div class="tag-content">
                        #项目标签
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="message-box">
        <div class="msg-1" />
        <div
          class="msg-2 pre-tooltip"
          :class="{
            'deep-color': type === 'preStartTime',
          }"
        >
          <template v-if="type === 'preStartTime'">
            <div class="tooltip" />
            <div class="tooltip-box time">
              <span>{{ formatDate(baseForm.preStartTime) }}开播</span>
            </div>
          </template>
        </div>
      </div>
      <div class="guest-box">
        <div class="box-title" />
        <div class="box-content">
          <div
            v-for="i in 3"
            :key="`guest-${i}`"
            class="rectangle-box"
          >
            <div
              v-for="j in 4"
              :key="`guest-item-${j}`"
              class="rectangle"
            />
          </div>
        </div>
      </div>
      <div class="focus-box">
        <div class="box-title" />
        <div class="box-content">
          <div class="round-box">
            <div
              v-for="j in 10"
              :key="`focus-item-${j}`"
              class="round-item"
            >
              <div
                v-if="j <= 6"
                class="round"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="audience-box">
        <div class="box-title" />
        <div class="box-content">
          <div
            v-for="i in 3"
            :key="`audience-${i}`"
            class="round-box"
          >
            <div
              v-for="j in 10"
              :key="`audience-item-${j}`"
              class="round-item"
            >
              <div
                v-if="i < 3 || j < 4"
                class="round"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LogoPanel from '@/views/components/LogoPanel';

export default {
  components: {
    LogoPanel,
  },
  props: {
    baseForm: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '', // guests invites preStartTime templateUrl uploadPoster PersonalRoadshow
    },
  },
  inject: ['pageEmitter'],
  data() {
    return {
      temporaryUrl: '',
    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'cardInfo']),
    isListDeep() {
      return this.type === 'guests' || this.projects || this.type === 'invites';
    },
    isShowUrl() {
      return this.temporaryUrl ? this.temporaryUrl : this.baseForm.posterUrl;
    },
    projects() {
      return this.type === 'Roadshow' || this.type === 'PersonalRoadshow';
    },
    cardInfoData() {
      return {
        ...this.cardInfo,
        investorType: 20,
        enterpriser: 2,
      };
    },
  },
  mounted() {
    this.pageEmitter.on('temporary-url-change', this.handleSelectTemporary);
  },
  methods: {
    formatDate(obj) {
      const time = new Date(parseInt(obj, 10));
      const y = time.getFullYear(); // 年
      let m = time.getMonth() + 1; // 月
      if (m < 10) { m = `0${m}`; }
      let d = time.getDate(); // 日
      if (d < 10) { d = `0${d}`; }
      let h = time.getHours(); // 时
      if (h < 10) { h = `0${h}`; }
      let mm = time.getMinutes(); // 分
      if (mm < 10) { mm = `0${mm}`; }
      return `${y}-${m}-${d}  ${h}：${mm}`;
    },
    handleSelectTemporary(val) {
      this.temporaryUrl = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 300px;
  height: 370px;
  box-sizing: content-box;
  border: 3px solid #dee3ed;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    width: 100%;
    height: 9px;
    background: #dee3ed;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 7.5px;

    .mini-point {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #fff;
      margin-right: 3px;
      position: relative;
      top: -1px;
    }
  }

  .content {
    width: 100%;
    flex: 1;
    background: #fff;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 8px 8px;

    .img-box,
    .message-box,
    .guest-box,
    .focus-box,
    .audience-box {
      width: 100%;
      margin-bottom: 10.5px;
      display: flex;
      justify-content: space-between;

      .box-title {
        width: 30.11px;
        height: 6.03px;
        background: #f0f3f7;
        margin-bottom: 6.03px;
        border-radius: 1px;
      }
    }

    .message-box {
      height: 26px;
      flex-direction: column;

      .msg-1,
      .msg-2 {
        background: #f0f3f7;
        border-radius: 1px;
        height: 10px;
      }

      .msg-1 {
        width: 127px;
      }

      .msg-2 {
        width: 63px;
      }
    }

    .guest-box {
      height: 55px;
      flex-direction: column;
    }

    .focus-box {
      height: 30px;
      flex-direction: column;
    }

    .audience-box {
      height: 75px;
      flex-direction: column;
      margin-bottom: 0;
    }
  }
}

.img-box {
  height: 112px;
  flex-direction: row;
  margin-bottom: 6px;

  .list-box,
  .img {
    height: 112px;
    background: #f0f3f7;
    border-radius: 1px;
  }

  .img {
    width: 195px;
    position: relative;
    overflow: hidden;

    .play-btn {
      width: 30px;
      height: 30px;
      position: absolute;
      background: #dee3ed;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    > img {
      display: block;
      width: 195px;
      height: 112px;
    }
  }

  .list-box {
    width: 75px;
    padding: 4px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;

    .list {
      width: 100%;
      height: 12px;
      border-radius: 1px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4px;

      &.bg-color {
        background: #e4e8f0;
      }

      .item {
        width: 32px;
        height: 12px;
        background: #e4e8f0;
        border-radius: 1px;
      }
    }
  }
}

.list-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item {
    width: 100%;
    height: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo,
    .name {
      background: #e4e8f0;
    }

    .logo {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }

    .name {
      flex: 1;
      height: 6px;
      border-radius: 4px;
    }
  }
}

.box-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .rectangle-box {
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .rectangle {
      width: 55px;
      height: 10px;
      background: #f0f3f7;
      border-radius: 1px;
    }
  }

  .round-box {
    width: 100%;
    height: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .round-item {
      width: 17px;
      height: 17px;
    }

    .round {
      width: 17px;
      height: 17px;
      background: #f0f3f7;
    }
  }
}

.round {
  border-radius: 50%;
}

.party {
  border-radius: 1px;
}

.deep-color {
  background: #9aa6b5 !important;
}

.pre-tooltip {
  position: relative;

  .tooltip {
    z-index: 2;
    width: 10px;
    height: 10px;
    border-top: 1px solid #e4e5eb;
    border-left: 1px solid #e4e5eb;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateY(130%) translateX(-50%) rotate(45deg);
    background: #fff;
  }
}

.tooltip-box {
  z-index: 1;
  border: 1px solid #e4e5eb;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: absolute;
  bottom: 0;

  &.time {
    width: max-content;
    height: 40px;
    left: 0;
    transform: translateY(120%);
    padding: 11px 8px;

    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333b44;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  &.guest {
    width: 178px;
    left: -20px;
    transform: translateY(115%);
    padding: 7px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      margin-top: 0;
    }

    .weitu {
      display: block;
      width: 40px;
      height: 40px;
    }

    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333b44;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  &.project {
    width: 246px;
    height: 77px;
    left: 50%;
    transform: translateY(110%) translateX(-50%);
    padding: 7px 15px;
    display: flex;
    flex-direction: row;

    .project-img {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      overflow: hidden;
      margin-right: 8px;

      > img {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.project-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .project-name,
  .project-type,
  .tag-content {
    font-family: PingFangSC-Regular;
    letter-spacing: 0;
    color: #333b44;
    font-weight: 400;
    background: transparent !important;
  }

  .project-name {
    font-size: 14px;
    line-height: 20px;
  }

  .project-type {
    opacity: 0.6;
    font-size: 12px;
    line-height: 18px;
  }

  .tag-content {
    opacity: 0.4;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>

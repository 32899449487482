import axios from '@/utils/axios.ts';

// 上传文件
export async function upload(params) {
  return axios({
    url: '/mobi-investor/v1/liveConfig/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    __interceptorOptions__: { encode: false },
    data: params,
  });
}

// 发起live
export async function publishLive(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 编辑live
export async function updateLive(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 回显live
export async function getLiveInfo(roomId) {
  return axios({
    url: `/mobi-investor/v1/liveRoom/info/${roomId}`,
    method: 'get',
    __interceptorOptions__: {
      showResult: true,
    },
  });
}

// 系统模板
export async function getTemplateList() {
  return axios({
    url: '/mobi-investor/ossResource/getListByResourceUseType?resourceUseType=0',
    method: 'get',
  });
}

// 互关用户列表
export async function getFollowList() {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/getMutualFollowList?page=1&pageSize=999',
    method: 'get',
  });
}

// 查询自己的项目列表
export async function getOwnProjectList() {
  return axios({
    url: '/mobi-investor/v1/liveProject/ownProject',
    method: 'get',
  });
}

// 查询项目列表
export async function getProjectList(params) {
  return axios({
    url: '/mobi-investor/search/v2/company',
    method: 'get',
    params,
  });
}

<template lang="html">
  <el-dropdown
    placement="bottom-start"
    trigger="click"
    size="small"
    class="dropdown-wrapper"
    @command="handleSearch"
  >
    <el-input
      v-model="searchKeyword"
      :class="['top-search']"
      placeholder="请输入项目名称"
      clearable
      size="small"
      debounce="500"
      @input="searchBarFocus"
      @clear="searchClear"
    >
      <i
        slot="prefix"
        class="input-search-button iconfont icon-v_search-small"
      />
    </el-input>
    <el-dropdown-menu
      ref="searchDropdownMenu"
      slot="dropdown"
      v-loading="loading"
      :class="['search-history-item', !searchList.length ? 'search-history-item-hidden' : '']"
    >
      <el-dropdown-item
        v-for="(item, index) in searchList"
        :key="index"
        :command="item"
      >
        <span class="search-name"> {{ item.name || '-' }}</span>
        <span
          v-if="item.fullName"
          class="search-full-name"
        >({{ item.fullName || '-' }})</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { getProjectList } from '@/services/publish';

export default {
  data() {
    return {
      selectState: true,
      searchKeyword: '',
      searchList: [],
      loading: false,
    };
  },
  methods: {
    // 清空搜索关键词时清空下拉列表
    searchClear() {
      this.searchList = [];
    },
    // element下拉列表的点击事件
    handleSearch(item) {
      this.$emit('search-change', item);
      this.searchList = [];
      // 清空input搜索词
      this.searchKeyword = '';
    },
    // 检索词推荐
    async searchBarFocus(val) {
      val = val.trim();
      if (!val || !this.searchKeyword) {
        return;
      }
      this.loading = true;
      try {
        const result = await getProjectList({ keyword: this.searchKeyword });
        this.searchList = result.data;
      } catch {
        // 失败报错，不处理
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-menu-search {
  .dropdown-wrapper {
    width: 100%;
  }

  .top-search {
    &:hover {
      .el-input__inner {
        border: 1px solid #3e74ca;
      }
    }

    /deep/ .el-input__suffix {
      display: inline-block;
      height: 28px;
      line-height: 26px;

      .el-input__icon {
        line-height: 28px;
      }
    }

    /deep/ .el-input__prefix {
      width: 60px;
      display: inline-block;
      height: 28px;
      line-height: 26px;
      left: -15px;
      top: 0;
      color: #9699ac;

      .el-input__icon.el-input__clear {
        position: absolute;
        right: 63px;
      }

      .input-search-button {
        font-size: 16px;
        display: inline-block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .el-input__inner {
      border-radius: 4px;
      padding: 7px 0 7px 12px;
      height: 30px;
      font-size: 12px;
      line-height: 12px;
    }

    /deep/ input::-webkit-input-placeholder {
      font-size: 14px;
      color: #959aa2;
    }
  }
}

.search-history-item-hidden {
  display: none;
}

.el-popper.search-history-item {
  margin-top: 8px;

  &::-webkit-scrollbar-track-piece {
    padding: 8px 0;
    background-color: #fff;
  }
}

.search-history-item {
  width: 318px;
  max-height: 280px;
  overflow: auto;

  em {
    background-color: #ffef9b;
    color: inherit;
    font-style: normal;
  }

  .el-input-group__append {
    width: 60px;
    color: #fff;
  }

  .popper__arrow {
    left: 16px !important;
  }

  .el-dropdown-menu__item {
    font-size: 14px;
    padding: 0 8px;
    display: flex;

    .iconfont {
      line-height: 34px;
      color: #959aa2;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333b44;
      letter-spacing: 0;
      line-height: 34px;
    }

    .search-full-name {
      font-size: 12px;
      color: #959aa2;
      margin-left: 8px;
    }

    &:hover {
      background-color: #f2f8ff;
      color: #959aa2;
    }
  }
}
</style>

import { render, staticRenderFns } from "./SearchProjects.vue?vue&type=template&id=5ec7ce9f&scoped=true&"
import script from "./SearchProjects.vue?vue&type=script&lang=js&"
export * from "./SearchProjects.vue?vue&type=script&lang=js&"
import style0 from "./SearchProjects.vue?vue&type=style&index=0&id=5ec7ce9f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ec7ce9f",
  null
  
)

export default component.exports
<template>
  <div v-loading="loading">
    <scroll-bar
      class="form-content"
      :flex-layout="true"
    >
      <el-form
        ref="form"
        :model="form"
        class="more-form publish-form"
        size="mini"
        label-position="top"
        :rules="rules"
      >
        <div
          v-if="baseForm.liveType !== 0"
          class="form-item"
        >
          <el-form-item
            class="project-form-label"
            label="添加项目"
            prop="ccids"
            required
          >
            <!-- 个人路演 -->
            <project-select
              v-if="isPersonalRoadshow"
              project-type="PersonalRoadshow"
              :project-list.sync="projectLists"
              @list-change="handleProject"
            />
            <!-- 路演活动 -->
            <project-select
              v-if="isRoadshow"
              project-type="Roadshow"
              :project-list.sync="projectLists"
              @list-change="handleProject"
            />
          </el-form-item>
          <p class="input-tip project-input-tip">
            所选项目将展示在直播详情页内，进入直播间的人都可见<br>
            选择项目后，项目中的BP将自动成为路演中可播放的PPT资料<br>
            请确保鲸准APP内项目已上传最新BP<br>
            如有需要，可在“添加演示文稿”处重新上传项目相关资料
          </p>
        </div>
        <el-form-item
          label="添加演示文稿"
          prop="presentationUrls"
        >
          <presentation-upload
            :file-list="form.presentationUrls"
            @change-file-list="handlePresentationChange"
          />
          <p class="input-tip">
            我们将会在北京时间10:00-19:00进行文稿审核。审核通过后，直播中只有主持人可以操作播放文稿，播放时所有人可见。
          </p>
        </el-form-item>

        <el-form-item
          label="房间类型"
          prop="joinRoomType"
          required
        >
          <el-radio-group
            v-model="form.joinRoomType"
            class="live-radio join-room-type"
          >
            <el-radio :label="0">
              公开
            </el-radio>
            <el-radio :label="1">
              邀请制
            </el-radio>
          </el-radio-group>
          <p class="input-tip">
            <template v-if="form.joinRoomType === 0">
              创建一个公开直播活动，任何符合身份的用户都可以加入围观
            </template>
            <template v-else>
              仅受到邀请的人可以看见该场直播活动
            </template>
          </p>
        </el-form-item>

        <div class="form-item bottom-item">
          <el-form-item
            v-if="form.joinRoomType === 0"
            label="用户类型"
            prop="roomUserTypes"
          >
            <el-checkbox-group
              v-model="form.roomUserTypes"
              class="live-checkbox"
              @change="changeCheckbox"
            >
              <el-checkbox :label="0">
                不限
              </el-checkbox>
              <el-checkbox :label="1">
                创业者
              </el-checkbox>
              <el-checkbox :label="2">
                投资人
              </el-checkbox>
              <el-checkbox :label="3">
                服务机构
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item
            v-else
            label="邀请名单"
            prop="invites"
          >
            <guest-select
              placeholder="请选择邀请用户"
              :user-list="form.invites"
            />
          </el-form-item>
        </div>
      </el-form>
    </scroll-bar>
    <div class="footer">
      <el-button
        v-sensor:click="{
          $element_id: 'live_publish_submit',
          consumer_id: profile.uid
        }"
        type="primary"
        class="submit-button"
        @click="submitForm"
      >
        提交
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  publishLive, updateLive,
} from '@/services/publish';
import ScrollBar from '@/components/ScrollBar';
import PresentationUpload from './PresentationUpload';

export default {
  name: 'MoreInfoForm',
  inject: ['pageEmitter'],
  components: {
    ScrollBar,
    PresentationUpload,
    GuestSelect: () => import('./GuestSelect'),
    ProjectSelect: () => import('./ProjectSelect'),
  },
  props: {
    baseForm: {
      type: Object,
      default: () => ({}),
    },
    moreInfoForm: {
      type: Object,
      default: () => ({}),
    },
    projectForm: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    const validateCcids = (rule, value, callback) => {
      if (this.baseForm.liveType === 0) {
        callback();
      } else {
        if (!value.length) {
          callback(new Error('请选择项目'));
        } else {
          callback();
        }
      }
    };
    return {
      roomId: this.$route.query.id,
      loading: false,

      presentations: [], // 文稿预览列表
      projectLists: [], // 用于展示的项目列表
      form: {
        ccids: [], // 项目列表
        presentationUrls: [], // 文稿列表
        joinRoomType: 0, // 房间类型
        roomUserTypes: [0], // 用户类型
        invites: [], // 邀请人员
      },
      rules: {
        ccids: [
          { validator: validateCcids, trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'profile']),
    isPersonalRoadshow() { // 个人路演
      return this.baseForm.liveType === 1;
    },
    // 路演活动
    isRoadshow() {
      return this.baseForm.liveType === 2;
    },
  },
  watch: {
    moreInfoForm: {
      handler() {
        const keys = Object.keys(this.moreInfoForm);
        if (keys.length) {
          this.form = Object.freeze(this.moreInfoForm);
        }
      },
      deep: true,
      immediate: true,
    },
    projectForm: {
      handler(val) {
        if (val.length) {
          this.handleProjectFormValue(this.projectForm);
        }
      },
      deep: true,
      immediate: true,
    },
    'baseForm.liveType': {
      handler() {
        this.handleProject([]);
      },
    },
  },
  mounted() {
    this.pageEmitter.on('invites-change', this.handleSelectUser);
    this.pageEmitter.on('project-change', this.handleProject);
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    handleProjectFormValue(projectForm) {
      const newArray = projectForm.map((item) => {
        return {
          companyId: item.pid,
          companyName: item.name,
          companyLogo: item.logo,
          companyFullName: item.fullName,
        };
      });
      this.projectLists = [].concat(newArray);
    },
    handleProject(val) {
      // 用于表单提交
      this.form.ccids = val.map((item) => item.companyId);
      // 用于项目展示
      this.projectLists = Object.assign(val);

      this.$refs.form.validateField('ccids', (valid) => {
        if (valid) return false;
      });
    },
    handlePresentationChange(val) {
      this.form.presentationUrls = val;
    },

    // 用户类型选择筛选
    changeCheckbox(val) {
      const type = val[val.length - 1];
      if (type) {
        // eslint-disable-next-line
        this.form.roomUserTypes.splice(this.form.roomUserTypes.findIndex((item) => item === 0), 1);
        this.form.roomUserTypes.push(type);
      } else {
        this.form.roomUserTypes = [0];
      }
      this.form.roomUserTypes = [...new Set(this.form.roomUserTypes)];
      // 用户类型可同时选中三种
      // if (this.form.roomUserTypes.length === 3) {
      //   this.form.roomUserTypes = [0];
      // }
    },
    handleSelectUser(val) {
      this.form.invites = val;
    },
    // 提交
    submitForm() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = Object.assign([], this.form, this.baseForm);
          try {
            if (this.roomId) {
              params.id = this.roomId;
              await updateLive(params);
            } else {
              await publishLive(params);
            }
            this.$emit('publish-success');
          } catch (e) {
            const { response } = e;
            const { data } = response;
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./form.scss";

/deep/ .project-form-label.is-error > .el-form-item__content {
  height: 28px;

  .selector {
    border-color: #ec333a;
  }
}

.project-input-tip {
  margin-top: 0;
}

</style>

<template>
  <div>
    <div
      v-loading="loading"
      class="body-wrapper"
    >
      <scroll-bar
        v-if="followList.length"
        class="follow-list"
        :flex-layout="true"
      >
        <el-checkbox
          v-model="checkAll"
          class="select-all"
          @change="handleSelectAll"
        >
          全部选择
        </el-checkbox>
        <el-checkbox-group
          v-model="checkList"
          @change="handleCheckChange"
        >
          <template v-for="item in followList">
            <el-checkbox
              :key="item.followedUid"
              :label="item.followedUid"
            >
              <div class="follow">
                <logo-panel
                  :info="item"
                  class="avatar"
                />
                <div>
                  <div class="follow-name">
                    {{ item.name || '新用户' }}
                  </div>
                  <div class="follow-identity">
                    {{ item.intro || '-' }}
                  </div>
                </div>
              </div>
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </scroll-bar>

      <div
        v-else
        class="empty"
      >
        暂无互关好友，去关注更多
      </div>
    </div>
    <div class="footer">
      <el-button @click="handleClose">
        取消
      </el-button>
      <el-button
        type="primary"
        @click="handleConfirm"
      >
        确认<span v-if="checkList.length">({{ checkList.length }})</span>
      </el-button>
    </div>
  </div>
</template>
<script>
import { getFollowList } from '@/services/publish';
import LogoPanel from '@/views/components/LogoPanel';
import ScrollBar from '@/components/ScrollBar';

export default {
  name: '',
  inject: ['pageEmitter'],
  components: {
    ScrollBar,
    LogoPanel,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    defaultData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      followList: [],
      checkList: [],
      loading: false,
      checkAll: false,
    };
  },
  async mounted() {
    await this.getUserList();
    this.setUserList();
  },
  methods: {
    setUserList() {
      const { value } = this.defaultData;
      this.checkList = value.map((i) => i.uid);
      this.handleCheckChange(value);
    },
    async getUserList() {
      this.loading = true;
      try {
        const result = await getFollowList();
        if (result) {
          this.followList = Object.freeze(result.data);
        }
      } catch (error) {
        // console.log(error)
      } finally {
        this.loading = false;
      }
    },

    // 全选
    handleSelectAll(val) {
      this.checkList = val ? this.followList.map((item) => item.followedUid) : [];
    },

    handleCheckChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.followList.length; // 设置全选状态
    },

    handleClose() {
      this.pageEmitter.emit('hide-middle-form');
    },
    handleConfirm() {
      const list = this.checkList.map((i) => {
        const current = this.followList.find((item) => i === item.followedUid);
        return {
          name: current.name || '新用户',
          uid: i,
        };
      });

      if (this.type === 'guests') {
        this.pageEmitter.emit('guest-change', list);
      } else {
        this.pageEmitter.emit('invites-change', list);
      }

      this.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.body-wrapper {
  .follow-list {
    max-height: calc(100vh - 258px);
    padding-right: 30px;

    /deep/ {
      .el-checkbox {
        width: 100%;
        margin-right: 0;
        margin-top: 25px;
      }
    }
  }

  .follow {
    display: flex;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }

  .follow-name {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333b44;
    line-height: 20px;
  }

  .follow-identity {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #666b71;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 2px;
    width: 227px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /deep/ {
    .el-checkbox {
      position: relative;
    }

    .el-checkbox__input {
      position: absolute;
      top: 14px;
    }

    .el-checkbox__label {
      padding-left: 32px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #3e74ca;
      border-color: #3e74ca;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #3e74ca;
    }
  }

  .select-all {
    margin-top: 0 !important;

    /deep/ {
      .el-checkbox__label {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333b44;
        line-height: 20px;
      }

      .el-checkbox__input {
        top: 2px;
      }
    }
  }
}

.empty {
  font-size: 12px;
  color: #c1c4cb;
  line-height: 20px;
  text-align: center;
  padding-top: 84px;
  padding-bottom: 60px;
}

.footer {
  padding-top: 40px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;

  .el-button {
    width: 152px;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 400;
    font-family: PingFangSC-Regular;
  }
}
</style>

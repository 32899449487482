<template>
  <div class="body-wrapper">
    <el-upload
      :show-file-list="false"
      action=""
      :http-request="handleUpload"
    >
      <div class="upload-wrapper">
        <div
          v-if="url"
          class="upload-img-content"
        >
          <img
            :src="url"
            class="post-img"
          >
          <div class="filter">
            <div class="img-upload-btn">
              本地上传
            </div>
          </div>
        </div>

        <div
          v-else
          class="upload-btn"
        >
          本地上传
        </div>
      </div>
    </el-upload>

    <div class="exapmle-container">
      <div class="item">
        <img src="@/views/publish/img/example1.png">
        <div class="right">
          <div class="top">
            <i class="iconfont icon-ok yes" />
            <span class="title">封面设计建议</span>
          </div>
          <p class="desc">
            尺寸建议 16:9，尽量突显直播主题和企业信息
          </p>
        </div>
      </div>
      <div class="item">
        <img src="@/views/publish/img/example2.png">
        <div class="right">
          <div class="top">
            <i class="iconfont icon-qingchux no" />
            <span class="title error">错误示范</span>
          </div>
          <p class="desc">
            和品牌无关的、信息过多、很小的，字封面加入二维码、手机号等信息
          </p>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button @click="handleClose">
        取消
      </el-button>
      <el-button
        type="primary"
        @click="handleConfirm"
      >
        确认
      </el-button>
    </div>
  </div>
</template>
<script>
import { upload } from '@/services/publish';

export default {
  name: '',
  inject: ['pageEmitter'],
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      url: '',
      selectedPoster: false,
    };
  },
  mounted() {
    this.setDefaultUrl();
  },
  methods: {
    // 设置默认展示图片
    setDefaultUrl() {
      this.url = this.defaultData.value;
    },
    async handleUpload(params) {
      const { file } = params;
      if (!/^image\//.test(file.type)) {
        this.$message.error('上传格式错误！');
        return;
      }
      if (file.size > 1024 * 1024 * 5) {
        this.$message.error('上传文件大小不能超过5M');
        return;
      }
      const fd = new FormData();
      fd.append('file', file);
      const result = await upload(fd);
      this.url = result;
      this.selectedPoster = true;
      this.pageEmitter.emit('temporary-url-change', this.url);
    },
    handleClose() {
      this.pageEmitter.emit('temporary-url-change', '');
      this.pageEmitter.emit('hide-middle-form');
    },
    handleConfirm() {
      this.pageEmitter.emit('upload-poster-url-change', this.url);
      this.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;

  .el-button {
    width: 152px;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 400;
    font-family: PingFangSC-Regular;
  }
}

.body-wrapper {
  padding-right: 30px;
}

.upload-wrapper {
  width: 320px;
  height: 180px;
  background: #f9fafc;
  border: 1px dashed #e0e3e6;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .upload-btn {
    width: 100px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #3e74ca;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;

    &:hover {
      background: #4c8bee;
    }
  }
}

.upload-img-content {
  width: 100%;
  height: 100%;
  position: relative;

  .post-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .filter {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .img-upload-btn {
      width: 100px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      border: 1px solid #fff;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.exapmle-container {
  .item {
    display: flex;
    margin-top: 16px;

    .top {
      height: 20px;
      display: flex;
    }

    .right {
      margin-left: 16px;
    }

    img {
      width: 152px;
      height: 86px;
      border-radius: 4px;
    }

    .yes {
      font-size: 16px;
      color: #3e74ca;
      line-height: 20px;
    }

    .no {
      font-size: 18px;
      color: #9699ac;
      line-height: 20px;
    }

    .title {
      font-size: 14px;
      color: #333b44;
      margin-left: 8px;
      line-height: 20px;

      &.error {
        margin-left: 6px;
      }
    }

    .desc {
      font-size: 12px;
      color: #959aa2;
      margin-top: 4px;
      line-height: 18px;
    }
  }
}
</style>

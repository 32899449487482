<template>
  <div>
    <div
      v-loading="loading"
      class="body-wrapper"
    >
      <scroll-bar
        class="project-list"
        :flex-layout="true"
      >
        <el-checkbox
          v-model="checkAll"
          class="select-all"
          @change="handleSelectAll"
        >
          全部选择
        </el-checkbox>
        <el-checkbox-group
          v-model="checkList"
          @change="handleCheckChange"
        >
          <template v-for="item in projectList">
            <el-checkbox
              :key="item.companyId"
              :label="item.companyId"
            >
              <div class="project-wrapper">
                <div class="project-logo">
                  <img
                    v-if="item.companyLogo"
                    :src="item.companyLogo"
                  >
                  <span
                    v-else
                    class="logo-default"
                    :style="{
                      background: getRandomColor(item.companyId),
                    }"
                  >
                    {{ item.companyName && item.companyName[0] }}
                  </span>
                </div>
                <div class="project-right-wrapper">
                  <div class="short-name">
                    {{ item.companyName || '-' }}
                  </div>
                  <div class="full-name">
                    {{ item.companyFullName || '-' }}
                  </div>
                </div>
              </div>
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </scroll-bar>
    </div>
    <div class="footer">
      <el-button @click="handleClose">
        取消
      </el-button>
      <el-button
        type="primary"
        @click="handleConfirm"
      >
        确定({{ checkList.length }})
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getOwnProjectList } from '@/services/publish';
import ScrollBar from '@/components/ScrollBar';
import { getRandomColor } from '@/utils/logoColors';

export default {
  name: '',
  inject: ['pageEmitter'],
  components: {
    ScrollBar,
  },
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      projectList: [],
      checkList: [],
      loading: false,
      checkAll: false,
    };
  },
  computed: {
    ...mapState('user', ['profile']),
  },
  async mounted() {
    await this.getProjectList();
    this.setProjectList();
  },
  methods: {
    getRandomColor,
    async getProjectList() {
      this.loading = true;
      this.checkList = [];
      try {
        const result = await getOwnProjectList();
        if (result) {
          this.projectList = Object.freeze(result);
          if (this.projectList.length) {
            const firstId = this.projectList[0].companyId;
            this.checkList = [firstId];
            this.handleCheckChange(this.checkList);
          }
        }
      } catch (error) {
        // console.log(error)
      } finally {
        this.loading = false;
      }
    },
    setProjectList() {
      const { value } = this.defaultData;
      if (!value.length) return;
      this.checkList = value.map((i) => i.companyId);
      this.handleCheckChange(value);
    },
    // 全选
    handleSelectAll(val) {
      this.checkList = val ? this.projectList.map((item) => item.companyId) : [];
    },

    handleCheckChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.projectList.length; // 设置全选状态
    },

    handleClose() {
      this.pageEmitter.emit('hide-middle-form');
    },
    handleConfirm() {
      if (!this.checkList.length) {
        this.$message.warning('请至少选择一个项目');
        return;
      }
      const list = this.checkList.map((i) => {
        const current = this.projectList.find((item) => i === item.companyId);
        return {
          companyName: current.companyName,
          companyId: i,
        };
      });
      this.pageEmitter.emit('project-change', list);

      this.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.body-wrapper {
  padding-right: 30px;

  .project-list {
    max-height: calc(100vh - 258px);

    /deep/ {
      .el-checkbox {
        width: 100%;
        margin-right: 0;
        margin-top: 25px;
      }
    }
  }

  /deep/ {
    .el-checkbox {
      display: flex;
      align-items: center;
    }

    .el-checkbox__label {
      padding-left: 16px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #3e74ca;
      border-color: #3e74ca;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #3e74ca;
    }
  }

  .select-all {
    margin-top: 0 !important;

    /deep/ {
      .el-checkbox__label {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333b44;
        line-height: 20px;
      }

      .el-checkbox__input {
        top: 2px;
      }
    }
  }
}

.project-wrapper {
  display: flex;

  .project-logo {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-right: 16px;

    > img {
      width: 100%;
      height: 100%;
    }

    .logo-default {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
    }
  }

  .project-right-wrapper {
    flex: 1;
    width: 0;
  }

  .short-name {
    width: 100%;
    font-size: 14px;
    color: #333b44;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .full-name {
    font-size: 12px;
    color: #666b71;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.footer {
  padding-top: 40px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;

  .el-button {
    width: 152px;
    height: 40px;
    font-size: 16px;
    letter-spacing: 2px;
  }
}
</style>

<template>
  <div class="middle-container">
    <div class="title">
      {{ title }}
    </div>
    <div class="form-content">
      <!-- 选择嘉宾、邀请用户 -->
      <guests
        v-if="type === 'guests' || type === 'invites'"
        :type="type"
        :default-data="defaultData"
      />

      <!-- 上传海报 -->
      <poster-upload
        v-if="type === 'uploadPoster'"
        :default-data="defaultData"
      />
      <!-- 选择海报模板 -->
      <template-container
        v-if="type === 'templateUrl'"
        :default-data="defaultData"
      />

      <!-- 个人路演-添加项目 -->
      <add-projects
        v-if="type === 'PersonalRoadshow'"
        :default-data="defaultData"
      />
      <!-- 路演活动-添加项目 -->
      <search-projects
        v-if="type === 'Roadshow'"
        :default-data="defaultData"
      />
    </div>
  </div>
</template>
<script>
import Guests from './components/Guests';
import TemplateContainer from './components/TemplateContainer';
import PosterUpload from './components/PosterUpload';
import AddProjects from './components/AddProjects';
import SearchProjects from './components/SearchProjects';

export default {
  name: '',
  inject: ['pageEmitter'],
  components: {
    Guests,
    TemplateContainer,
    PosterUpload,
    AddProjects,
    SearchProjects,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    defaultData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.middle-container {
  padding: 0 30px;
  width: 380px;
  border-right: 1px solid rgba(39, 54, 78, 0.08);

  .title {
    width: 350px;
    padding-top: 40px;
    padding-bottom: 16px;
    font-size: 16px;
    color: #333b44;
    line-height: 22px;
    font-weight: 500;
  }

  .form-content {
    width: 350px;
  }
}
</style>

<template>
  <div>
    <el-upload
      class="live-uploade-wrapper"
      drag
      action=""
      :http-request="handleUpload"
      :show-file-list="false"
    >
      <div class="upload-btn">
        <div class="img-container">
          <img
            class="upload-img"
            src="@/assets/img/upload.png"
          >
        </div>

        <p class="info">
          将文件拖拽到此处，或<b>点击上传</b>
        </p>
        <p class="type">
          仅支持扩展名：.ppt / .pptx / .pdf，小于5MB
        </p>
      </div>
    </el-upload>
    <ul v-if="list.length">
      <li
        v-for="(item, index) in list"
        :key="`${item.name}${index}`"
        class="file-item"
      >
        <div class="left">
          <img
            :src="getFileType(item.name)"
            class="suffix-icon"
          >
          <span class="file-name">{{ item.name }}</span>
        </div>
        <div
          class="right"
          @mouseenter="item.showDelete = true"
          @mouseleave="item.showDelete = false"
        >
          <i
            v-if="item.showDelete"
            class="iconfont icon-qingchux"
            @click="deleteFile(index)"
          />
          <i
            v-else
            class="iconfont icon-v2_okk"
          />
        </div>
      </li>
    </ul>
    <div
      v-show="fileLoading"
      class="file-loading"
    >
      <img src="@/assets/img/loading-icon.gif">
      请耐心等待，BP正在上传中...
    </div>
  </div>
</template>
<script>
import { upload } from '@/services/publish';
import fileTypeImgs from '@/utils/fileTypeImgs';

const fileTypeImgList = [
  'OTHER',
  'BMP',
  'GIF',
  'JPG',
  'PDF',
  'XLS',
  'TXT',
  'DOC',
  'VOICE',
  'PPT',
  'HTML',
];
export default {
  name: '',
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      fileLoading: false,
      fileTypeImgs,
      fileTypeImgList,
    };
  },
  watch: {
    fileList: {
      handler() {
        if (this.fileList.length) {
          this.list = Object.freeze(this.fileList).map((item) => {
            return {
              ...item,
              showDelete: false,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async handleUpload(params) {
      const { file } = params;
      const { name } = file;
      if (!(/.pdf/.test(name) || /.ppt/.test(name))) {
        this.$message.error('上传格式错误！');
        return;
      }
      if (file.size > 1024 * 1024 * 5) {
        this.$message.error('上传文件大小不能超过5M');
        return;
      }

      this.fileLoading = true;
      const fd = new FormData();
      fd.append('file', file);
      const url = await upload(fd);
      this.list.push({
        name,
        presentationUrls: url,
        showDelete: false,
      });
      this.emitList();
      this.fileLoading = false;
    },
    deleteFile(index) {
      this.list.splice(index, 1);
      this.emitList();
    },
    emitList() {
      const list = this.list.map((item) => {
        const { name, presentationUrls } = item;
        return {
          name,
          presentationUrls,
        };
      });
      this.$emit('change-file-list', list);
    },
    // 获取文件路径后缀名
    getSuffix(filePath) {
      const index = filePath.lastIndexOf('.');
      const suffix = filePath.substr(index + 1).toUpperCase();
      return suffix;
    },
    getFileType(filePath) {
      const suffix = this.getSuffix(filePath);
      const fileTypeIndex = this.fileTypeImgList.findIndex((item) => (suffix.indexOf(item) >= 0));
      const typeIndex = (fileTypeIndex === -1) ? 0 : fileTypeIndex;
      return this.fileTypeImgs[typeIndex];
    },

  },
};
</script>
<style lang="scss" scoped>
.live-uploade-wrapper {
  height: 172px;
  margin-bottom: 8px;

  /deep/ .el-upload {
    width: 100%;
    height: 100%;
  }

  /deep/ .el-upload-dragger {
    background: #f9fafc;
    border: 1px dashed #e0e3e6;
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
}

.upload-btn {
  padding-top: 30px;
  padding-bottom: 30px;

  .img-container {
    height: 54px;
  }

  .upload-img {
    width: 54px;
    height: 54px;
  }

  .info {
    font-size: 14px;
    color: #333b44;
    line-height: 20px;
    margin-top: 16px;

    b {
      color: #3e74ca;
      margin-left: 8px;
    }
  }

  .type {
    font-size: 12px;
    color: #959aa2;
    line-height: 18px;
    margin-top: 4px;
  }
}

.file-item {
  display: flex;
  justify-content: space-between;
  height: 18px;
  margin-bottom: 8px;
  line-height: 18px;

  .suffix-icon {
    margin-right: 4px;
  }

  .file-name {
    max-width: 298px;
    font-size: 12px;
    color: #666b71;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-v2_okk {
    color: #093;
  }

  .icon-qingchux {
    color: #9699ac;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    left: 1px;
  }
}

.file-loading {
  font-size: 12px;
  color: #666b71;

  img {
    width: 16px;
    height: 16px;
  }
}
</style>

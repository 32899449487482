<template>
  <div>
    <div
      class="selector"
      @click="clickSelector"
    >
      <div class="left">
        <div
          v-if="checkMemberStr"
          class="name-containter"
        >
          <span class="check-name">{{ checkMemberStr }}</span>
          <span v-if="userList.length > maxLength">等</span>
        </div>

        <span
          v-else
          class="placeholder"
        >{{ placeholder }}</span>
      </div>
      <div class="right">
        <span
          v-if="userList.length > maxLength"
          class="selector-count"
        >{{ userList.length }}</span>
        <i class="iconfont icon-v_arrow-right" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: '',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    userList: {
      type: Array,
      default: () => [],
    },
  },
  inject: ['pageEmitter'],
  data() {
    return {
      loading: false,
      maxLength: 5,
    };
  },
  computed: {
    checkMemberStr() {
      const list = Object.freeze(this.userList).map((item) => item.name);
      let str = '';
      if (this.userList.length) {
        str = list.slice(0, this.maxLength).join('，');
      }
      return str;
    },
  },
  methods: {
    async clickSelector() {
      if (this.placeholder === '请选择嘉宾') {
        this.pageEmitter.emit('show-form-item', {
          value: 'guests',
          title: '选择嘉宾',
          defaultData: {
            value: this.userList,
          },
        });
      } else {
        this.pageEmitter.emit('show-form-item', {
          value: 'invites',
          title: '选择邀请用户',
          defaultData: {
            value: this.userList,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.selector {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e5eb;
  border-radius: 4px;
  padding: 0 7px;
  height: 28px;

  &:hover {
    border-color: #3e74ca;
  }
}

.placeholder {
  color: #959aa2;
  font-size: 14px;
  line-height: 26px;
}

.name-containter {
  overflow: hidden;

  span {
    color: #333b44;
    float: left;
  }
}

.check-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 285px;
  margin-right: 3px;
}

.selector-count {
  font-size: 12px;
  position: relative;
  top: -2px;
  color: #959aa2;
  margin-right: 4px;
}

.icon-v_arrow-right {
  color: #9699ac;
  line-height: 26px;
}

</style>

<template>
  <div class="step-container">
    <div
      class="item"
      @click="clickStepOne"
    >
      <template>
        <img
          v-if="activeStep === 1"
          src="./img/left_active.svg"
        >
        <img
          v-else
          src="./img/left.svg"
        >
      </template>
      <span
        class="text"
        :class="{'active': activeStep === 1, 'finished': finishedStep.includes(1)}"
      >1 基本信息</span>
    </div>
    <div class="item">
      <template>
        <img
          v-if="activeStep === 2"
          src="./img/center_active.svg"
        >
        <img
          v-else
          src="./img/center.svg"
        >
      </template>
      <span
        class="text"
        :class="{'active': activeStep === 2, 'finished': finishedStep.includes(2)}"
      >2 更多资料</span>
    </div>
    <div class="item">
      <template>
        <img
          v-if="activeStep === 3"
          src="./img/right_active.svg"
        >
        <img
          v-else
          src="./img/right.svg"
        >
      </template>
      <span
        class="text"
        :class="{'active': activeStep === 3, 'finished': finishedStep.includes(3)}"
      >3 提交审核</span>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    activeStep: {
      type: Number,
      default: 1,
    },
    finishedStep: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

    };
  },
  methods: {
    clickStepOne() {
      this.$emit('click-step-one');
    },
  },
};
</script>
<style lang="scss" scoped>
.step-container {
  display: flex;

  .item {
    width: 116px;
    height: 40px;
    position: relative;
  }

  .text {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 40px;
    color: #959aa2;
    font-size: 16px;

    &.active {
      font-family: PingFangSC-Medium;
      color: #3e74ca;
    }

    &.finished {
      color: #3e74ca;
      cursor: pointer;
    }
  }
}
</style>

import OTHER from '@/assets/img/report/OTHER.svg';
import BMP from '@/assets/img/report/BMP.svg';
import GIF from '@/assets/img/report/GIF.svg';
import JPG from '@/assets/img/report/JPG.svg';
import PDF from '@/assets/img/report/PDF.svg';
import XLS from '@/assets/img/report/XLS.svg';
import TXT from '@/assets/img/report/TXT.svg';
import DOC from '@/assets/img/report/DOC.svg';
import VOICE from '@/assets/img/report/VOICE.svg';
import PPT from '@/assets/img/report/PPT.svg';
import HTML from '@/assets/img/report/HTML.svg';

export default Object.freeze([
  OTHER,
  BMP,
  GIF,
  JPG,
  PDF,
  XLS,
  TXT,
  DOC,
  VOICE,
  PPT,
  HTML,
]);

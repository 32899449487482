<template>
  <div class="publish-container">
    <div
      v-loading="loading"
      class="main-container"
    >
      <div
        v-if="showFormItem"
        class="cover-filter"
      />
      <h2 class="page-title">
        {{ title }}
      </h2>
      <step-container
        class="step-container"
        :active-step="activeStep"
        :finished-step="finishedStep"
        @click-step-one="handleClickStepOne"
      />

      <div class="form-container">
        <keep-alive>
          <!-- 基本信息 -->
          <base-info-form
            v-if="activeStep === 1"
            :base-form="baseForm"
            :local-url="localUrl"
            @submit-form="handleSubmitBaseForm"
            @results-preview="handleResultsPreview"
          />
          <!-- 更多资料 -->
          <more-info-form
            v-else-if="activeStep === 2"
            :base-form="baseForm"
            :project-form="projectForm"
            :more-info-form="moreInfoForm"
            @publish-success="handlePublishSuccess"
          />
        </keep-alive>
      </div>
    </div>

    <!-- 个别字段中间表单填写部分 -->
    <middle-form-container
      v-if="showFormItem"
      class="middle-form-container"
      :type="formItemType"
      :title="formItemTitle"
      :default-data="formItemDefault"
    />

    <div class="results-preview-box">
      <!-- guests preStartTime templateUrl uploadPoster PersonalRoadshow-->
      <results-preview
        v-if="showFormItem"
        :type="formItemType"
        :base-form="baseForm"
        class="results-preview"
      />
      <img
        v-else
        class="results-preview img"
        src="./img/live.png"
      >
    </div>

    <!-- 发起live成功弹框 -->
    <submit-success-dialog
      v-if="submitSuccesVisible"
      :submit-succes-visible="submitSuccesVisible"
      :visible.sync="submitSuccesVisible"
    />

    <!-- 未认证用户提示认证弹框 -->
    <launch-live-dialog
      :visible="verifyDialogVisible"
      @close="verifyDialogVisible = false"
    />
  </div>
</template>
<script>
import Emitter from 'wolfy87-eventemitter';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLiveInfo } from '@/services/publish';
import StepContainer from './components/StepContainer';
import BaseInfoForm from './components/BaseInfoForm';
import MoreInfoForm from './components/MoreInfoForm';
import MiddleFormContainer from './MiddleFormContainer';
import ResultsPreview from './components/ResultsPreview';

export default {
  name: 'Publish',
  components: {
    StepContainer,
    BaseInfoForm,
    MoreInfoForm,
    MiddleFormContainer,
    ResultsPreview,
    LaunchLiveDialog: () => import('@/views/components/LaunchLiveDialog'),
    SubmitSuccessDialog: () => import('./components/SubmitSuccessDialog'),
  },
  provide() {
    return {
      // 模块之间的事件触发
      pageEmitter: this.pageEmitter,
    };
  },
  data() {
    return {
      roomId: this.$route.query.id,
      pageEmitter: new Emitter(),

      activeStep: 1,
      introActive: false,
      finishedStep: [],
      loading: false,
      baseForm: {},
      moreInfoForm: {},
      projectForm: [],
      guests: [],
      localUrl: '',

      liveInfo: {},
      submitSuccesVisible: false,
      backStatus: true,
      showFormItem: false,
      formItemType: '',
      formItemTitle: '',
      formItemDefault: {},

      showPreview: true,

      verifyDialogVisible: false,
    };
  },

  computed: {
    ...mapState('user', ['isLogin', 'profile']),
    ...mapGetters('user', [
      'isEnterpriser',
      'isInvestor',
      'isOrg',
    ]),
    title() {
      return this.roomId ? '编辑直播' : '发起直播';
    },
  },
  mounted() {
    this.pageEmitter.on('show-form-item', this.handleClickFormItem);
    this.pageEmitter.on('hide-middle-form', this.handleHideMiddleForm);
    if (!this.isLogin) {
      this.showLoginDialog();
      return;
    }
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    if (this.backStatus) {
      this.$confirm('您将跳转至直播列表页，本页填写的内容将不会被保存', '提示', {
        customClass: 'route-confirm-dialog',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'route-confirm-btn',
        cancelButtonClass: 'route-cancel-btn',
      }).then(() => {
      // 正常跳转
        next();
      }).catch(() => {
        window.history.go(1);
      });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    init() {
      setTimeout(() => {
        if (this.isLogin && !this.isEnterpriser && !this.isInvestor && !this.isOrg) { // 无认证用户身份引导认证
          this.verifyDialogVisible = true;
          return;
        }
        if (this.roomId) {
          this.getLiveInfo();
        }
      }, 1000);
    },
    // 回显
    async getLiveInfo() {
      this.loading = true;
      try {
        const result = await getLiveInfo(this.roomId);
        if (result) {
          this.liveInfo = Object.freeze(result);
          const {
            liveTheme, liveType, preStartTime, admins, guests, introduce, posterUrl,
            presentationUrls, joinRoomType, roomUserTypes, invites, projectForm,
          } = this.liveInfo;

          this.localUrl = posterUrl;
          this.baseForm = {
            liveTheme, liveType, preStartTime, admins, guests, introduce, posterUrl,
          };

          const ccids = projectForm.map((item) => item.pid);
          this.moreInfoForm = {
            ccids, presentationUrls, joinRoomType, roomUserTypes, invites,
          };
          this.projectForm = [].concat(projectForm);
        }
      } catch (error) {
        // console.log(error)
      } finally {
        this.loading = false;
      }
    },
    handleClickStepOne() {
      this.activeStep = 1;
    },
    handleSubmitBaseForm(val) {
      this.baseForm = val;
      this.finishedStep = [1];
      this.activeStep = 2;
    },
    handlePublishSuccess() {
      this.backStatus = false;
      this.submitSuccesVisible = true;
    },
    handleResultsPreview(val) {
      this.baseForm = val;
    },
    // 切换中间编辑表单内容
    handleClickFormItem({ value, title, defaultData }) {
      this.showFormItem = true;
      this.formItemType = value;
      this.formItemTitle = title;
      this.formItemDefault = defaultData;
    },
    handleHideMiddleForm() {
      this.showFormItem = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.publish-container {
  display: flex;
  min-height: calc(100vh - 60px);

  .results-preview-box {
    flex: 1;
    position: relative;
    background: rgb(240, 242, 245);

    .results-preview {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.img {
        display: block;
        width: 400px;
      }
    }
  }

  .main-container {
    width: calc(50vw - 170px);
    min-width: 380px;
    padding: 40px 0;
    padding-left: calc(50vw - 550px);
    box-shadow: 2px 0 8px 0 rgba(39, 54, 78, 0.08);
    z-index: 1;
    position: relative;

    .page-title {
      opacity: 0.9;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #333b44;
      line-height: 26px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    .step-container {
      padding-right: 30px;
      padding-bottom: 30px;
    }

    .cover-filter {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.6);
      z-index: 99999;
    }
  }

  .middle-form-container {
    padding: 0 0 30px 30px;
  }
}
</style>

<style lang="scss">
.route-confirm-dialog {
  padding-bottom: 0;

  .el-message-box__header {
    border-bottom: 1px  #e4e5eb solid;

    .el-message-box__title {
      span {
        line-height: 22px;
        font-weight: 500;
        font-size: 16px;
        color: #333b44;
      }
    }

    .el-message-box__close {
      font-size: 16px;
    }
  }

  .el-message-box__content {
    padding: 30px;
  }

  .el-message-box__btns {
    padding: 10px 30px;
    border-top: 1px  #e4e5eb solid;

    .el-button {
      width: 72px;
      height: 30px;
      border-radius: 4px;
    }
  }
}
</style>

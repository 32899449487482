<template>
  <div class="poster-container">
    <!-- 上传海报 -->
    <template>
      <div
        v-if="localPosterUrl"
        class="upload-container"
        @mouseenter="showUploadBtn = true"
        @mouseleave="showUploadBtn = false"
      >
        <div
          class="img-content"
        >
          <span
            v-show="showUploadBtn"
            class="re-upload-btn"
            @click="uploadPoster"
          >重新上传</span>
          <div
            v-if="selectedPoster"
            class="filter"
          />
          <img
            :src="localPosterUrl"
            class="post-img upload-item"
          >
        </div>
        <div
          class="check-container"
          @click="checkLocalPoster"
        >
          <div class="img-checkbox">
            <img
              v-if="selectedPoster"
              class="checked"
              src="@/views/publish/img/complete.svg"
              alt=""
            >
            <span
              v-else
              class="default"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="system-post-btn upload-btn upload-item"
        @click="uploadPoster"
      >
        <i class="iconfont icon-add1" />
        <p>上传直播封面</p>
      </div>
    </template>

    <!-- 选择系统模板 -->
    <template>
      <div
        v-if="templateUrl"
        class="chosen-container"
        @mouseenter="showChooseBtn = true"
        @mouseleave="showChooseBtn = false"
      >
        <div
          class="img-content"
          @click="chooseTemplate"
        >
          <span
            v-show="showChooseBtn"
            class="re-upload-btn"
          >重新选择</span>
          <div
            v-if="selectedTemplate"
            class="filter"
          />
          <img
            :src="templateUrl"
            class="post-img upload-item"
          >
        </div>

        <div
          class="check-container"
          @click="checkTemplate"
        >
          <div class="img-checkbox">
            <img
              v-if="selectedTemplate"
              class="checked"
              src="@/views/publish/img/complete.svg"
              alt=""
            >
            <span
              v-else
              class="default"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="system-post-btn upload-btn upload-item"
        @click="chooseTemplate"
      >
        <i class="iconfont icon-add1" />
        <p>选择系统模版</p>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: '',
  inject: ['pageEmitter'],
  props: {
    localUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      templateUrl: '',
      localPosterUrl: '', // 本地海报地址
      selectedPoster: false,
      selectedTemplate: false,
      showUploadBtn: false,
      showChooseBtn: false,

      url: '',
    };
  },
  watch: {
    localUrl: {
      handler(val) {
        this.localPosterUrl = val;
        this.selectedPoster = true;
        this.selectedTemplate = false;
      },
      immediate: true,
    },
  },
  mounted() {
    this.pageEmitter.on('template-url-change', this.handleSelectTemplate);
    this.pageEmitter.on('upload-poster-url-change', this.handleUploadPoster);
  },
  methods: {
    uploadPoster() {
      this.pageEmitter.emit('show-form-item', {
        value: 'uploadPoster',
        title: '上传直播封面',
        defaultData: {
          value: this.localPosterUrl,
        },
      });
    },
    chooseTemplate() {
      this.pageEmitter.emit('show-form-item', {
        value: 'templateUrl',
        title: '选择直播封面',
        defaultData: {
          value: this.templateUrl,
        },
      });
    },

    // 选中上传的图片
    handleUploadPoster(val) {
      this.localPosterUrl = val;
      this.selectUploadPoster();
    },
    // 选中系统模板
    handleSelectTemplate(val) {
      this.templateUrl = val;
      this.selectTemplate();
    },
    // 切换选中上传封面
    checkLocalPoster() {
      if (this.selectedPoster) {
        return;
      }
      this.selectUploadPoster();
    },
    // 切换选中模板
    checkTemplate() {
      if (this.selectedTemplate) {
        return;
      }
      this.selectTemplate();
    },

    selectUploadPoster() {
      this.url = this.localPosterUrl;
      this.selectedPoster = true;
      this.selectedTemplate = false;
      this.emitUrl();
    },
    selectTemplate() {
      this.url = this.templateUrl;
      this.selectedPoster = false;
      this.selectedTemplate = true;
      this.emitUrl();
    },

    emitUrl() {
      this.$emit('poster-url-change', this.url);
    },
  },
};
</script>

<style lang="scss" scoped>

.poster-container {
  display: flex;
  justify-content: space-between;

  .upload-container,
  .chosen-container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    .img-checkbox {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 1000;
      cursor: pointer;

      .checked {
        position: relative;
        top: -2px;
      }

      .default {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 1px;
        border: 1px solid #e4e5eb;
        display: inline-block;
        background: #fff;
        position: relative;
        top: -3px;
      }
    }
  }

  .re-upload-btn {
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover {
      background: #3e74ca;
    }
  }

  .img-content {
    width: 167px;
    height: 100px;
    position: relative;

    .filter {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    }
  }

  .upload-item {
    width: 167px;
    height: 100px;
    border-radius: 4px;
  }

  .upload-btn {
    background: #f9fafc;
    border: 1px dashed #e0e3e6;
    text-align: center;
    font-size: 14px;
    color: #959aa2;
    line-height: 20px;
    padding-top: 28px;
    cursor: pointer;

    .icon-add1 {
      font-size: 24px;
    }
  }

  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
    position: relative;
    z-index: 2000;

    i {
      font-size: 24px;
      font-weight: 400;
    }

    &.placeholder {
      font-size: 12px;
      color: #c0c4cc;
    }
  }

  .check-container {
    width: 100%;
    height: 68px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
</style>

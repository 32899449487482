<template>
  <div>
    <scroll-bar
      v-loading="loading"
      class="body-wrapper"
      :flex-layout="true"
    >
      <div class="list-wrapper">
        <template v-for="(item, index) in templateList">
          <div
            :key="index"
            class="img-item"
            @click="handleSelectPic(item, index)"
          >
            <el-image
              :src="item.resourceUrl"
              lazy
              class="template-img"
            >
              <div
                slot="placeholder"
                class="image-slot placeholder"
              >
                加载中...
              </div>
              <div
                slot="error"
                class="image-slot"
              >
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div
              v-if="item.checked"
              class="filter"
            />
            <div class="checkbox">
              <img
                v-if="item.checked"
                class="checked"
                src="@/views/publish/img/complete.svg"
                alt=""
              >
              <span
                v-else
                class="default"
              />
            </div>
          </div>
        </template>
      </div>
    </scroll-bar>
    <div class="footer">
      <el-button @click="handleClose">
        取消
      </el-button>
      <el-button
        type="primary"
        @click="handleConfirm"
      >
        确认
      </el-button>
    </div>
  </div>
</template>
<script>
import ScrollBar from '@/components/ScrollBar';
import { getTemplateList } from '@/services/publish';

export default {
  name: '',
  inject: ['pageEmitter'],
  components: {
    ScrollBar,
  },
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      templateList: [],
      loading: true,
      url: '',
    };
  },
  mounted() {
    this.setDefaultUrl();
    this.getTemplateList();
  },
  methods: {
    async getTemplateList() {
      this.loading = true;
      try {
        const result = await getTemplateList();
        this.templateList = Object.freeze(result).map((item) => {
          return {
            ...item,
            checked: item.resourceUrl === this.url,
          };
        });
      } catch (error) {
        // console.log(error)
      } finally {
        this.loading = false;
      }
    },
    // 设置默认展示图片
    setDefaultUrl() {
      this.url = this.defaultData.value;
    },
    handleSelectPic(val, index) {
      this.templateList.forEach((item) => {
        item.checked = false;
      });
      this.templateList[index].checked = true;
      this.url = val.resourceUrl;
      this.pageEmitter.emit('temporary-url-change', this.url);
    },

    handleClose() {
      this.pageEmitter.emit('temporary-url-change', '');
      this.pageEmitter.emit('hide-middle-form');
    },
    handleConfirm() {
      this.pageEmitter.emit('template-url-change', this.url);
      this.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  padding-top: 40px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;

  .el-button {
    width: 152px;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 400;
    font-family: PingFangSC-Regular;
  }
}

.body-wrapper {
  max-height: calc(100vh - 258px);
  padding-right: 30px;
}

.list-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.img-item {
  width: 156px;
  height: 90px;
  margin-right: 8px;
  margin-top: 8px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  border: 2px solid #fff;

  &:hover {
    border-color: #328bf5;
  }

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
    position: relative;
    z-index: 101;

    i {
      font-size: 24px;
      font-weight: 400;
    }

    &.placeholder {
      font-size: 12px;
      color: #c0c4cc;
    }
  }

  .template-img {
    width: 152px;
    height: 86px;
    border-radius: 4px;
  }

  .filter {
    width: 152px;
    height: 86px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .checkbox {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 100;

    .checked {
      position: relative;
      top: -3px;
    }

    .default {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin: 1px;
      border: 1px solid #e4e5eb;
      display: inline-block;
      background: #fff;
      position: relative;
      top: -3px;
    }
  }
}
</style>

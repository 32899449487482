<template>
  <div v-loading="loading">
    <scroll-bar
      class="form-content"
      :flex-layout="true"
    >
      <el-form
        ref="form"
        :model="form"
        class="base-form publish-form"
        size="mini"
        label-position="top"
        :rules="rules"
      >
        <el-form-item
          label="直播主题"
          prop="liveTheme"
        >
          <el-input
            v-model.trim="form.liveTheme"
            placeholder="请输入直播主题"
            maxlength="30"
          />
          <div
            class="desc-count"
            :class="themeLength>30 ? 'err-desc-count': ''"
          >
            {{ themeLength }}/30
          </div>
        </el-form-item>

        <el-form-item
          label="直播类型"
          prop="liveType"
        >
          <el-radio-group
            v-model="form.liveType"
            class="live-radio live-type"
          >
            <el-radio :label="0">
              分享会
            </el-radio>
            <el-radio
              v-if="isEnterpriser"
              :label="1"
            >
              个人路演
            </el-radio>
            <el-radio
              v-if="isInvestor || isOrg"
              :label="2"
            >
              路演活动
            </el-radio>
          </el-radio-group>
          <p class="input-tip">
            <template v-if="form.liveType === 0">
              任何平台认证用户皆可发起直播活动，观点/见解/经验分享
            </template>
            <template v-else-if="form.liveType === 1">
              平台认证创业者发起的针对自己项目的云路演，单场直播时长为一小时
            </template>
            <template v-else>
              平台认证投资人/服务机构可开启的多项目云路演
            </template>
          </p>
        </el-form-item>

        <div class="form-item">
          <el-form-item
            label="直播时间"
            prop="preStartTime"
          >
            <el-date-picker
              v-model="form.preStartTime"
              class="live-date-picker"
              popper-class="live-date-popper"
              type="datetime"
              :clearable="false"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="expireTimeOption"
              placeholder="请选择开始时间"
              prefix-icon="el-icon-date"
            />
          </el-form-item>
          <p class="input-tip">
            直播申请资料需人工审核，1个工作日内给出反馈，请酌情设置开播时间。
          </p>
        </div>

        <el-form-item
          label="管理员"
          prop="admins"
        >
          <el-input
            v-model="form.admins[0].name"
            disabled
          />
        </el-form-item>

        <el-form-item
          label="嘉宾"
          prop="guests"
        >
          <guest-select
            placeholder="请选择嘉宾"
            :user-list="form.guests"
          />
        </el-form-item>

        <el-form-item
          label="直播详情"
          prop="introduce"
        >
          <el-input
            v-model="form.introduce"
            class="live-textarea"
            type="textarea"
            placeholder="请填写直播详情"
            maxlength="500"
          />
          <div
            class="desc-count"
            :class="descLength>500 ? 'err-desc-count': ''"
          >
            {{ descLength }}/500
          </div>
        </el-form-item>

        <div class="form-item bottom-item">
          <el-form-item
            label="直播封面"
            prop="posterUrl"
          >
            <poster-select
              :local-url="localUrl"
              @poster-url-change="handlePosterUrlChange"
            />
          </el-form-item>
          <p class="input-tip">
            精心设计的封面能帮您获得更多关注，建议比例为16:9，5MB以内
          </p>
        </div>
      </el-form>
    </scroll-bar>
    <div class="footer">
      <el-button
        type="primary"
        class="submit-button"
        @click="submitForm"
      >
        下一步
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLiveInfo } from '@/services/publish';
import ScrollBar from '@/components/ScrollBar';
import GuestSelect from './GuestSelect';
import PosterSelect from './PosterSelect';

export default {
  name: 'BaseInfoForm',
  components: {
    ScrollBar,
    GuestSelect,
    PosterSelect,
  },
  props: {
    baseForm: {
      type: Object,
      default: () => ({}),
    },
    localUrl: {
      type: String,
      default: '',
    },
  },
  inject: ['pageEmitter'],
  data() {
    const validateTime = (rule, value, callback) => {
      if (value <= Date.now()) {
        callback(new Error('选择时间必须大于当前时间'));
      } else {
        callback();
      }
    };
    const validateIntroduce = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请输入1-500字文本描述'));
      } else {
        callback();
      }
    };
    return {
      roomId: this.$route.query.id,
      loading: false,
      liveInfo: {}, // 回显直播信息
      form: {
        liveTheme: '', // Live主题
        liveType: 0, // live类型
        preStartTime: '', // live时间
        admins: [{ // 管理员
          name: '',
          uid: '',
        }],
        guests: [], // 嘉宾列表
        introduce: '', // live描述
        posterUrl: '', // 封面
      },
      rules: {
        liveTheme: [
          {
            required: true, min: 1, max: 30, message: '请输入1-30字文本', trigger: ['change', 'blur'],
          },
        ],
        liveType: [
          { required: true, message: '请选择直播类型', trigger: 'blur' },
        ],
        preStartTime: [
          { required: true, message: '请选择直播时间', trigger: 'blur' },
          { validator: validateTime, trigger: 'blur' },
        ],
        introduce: [
          {
            required: true, min: 1, max: 500, message: '请输入1-500字文本描述', trigger: ['change', 'blur'],
          },
          { validator: validateIntroduce, trigger: 'blur' },
        ],
        posterUrl: [
          { required: true, message: '请添加直播封面', trigger: 'blur' },
        ],
      },
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },

    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'profile']),
    ...mapGetters('user', [
      'isEnterpriser',
      'isInvestor',
      'isOrg',
    ]),
    themeLength() {
      return this.form.liveTheme.length || 0;
    },
    descLength() {
      return this.form.introduce.length || 0;
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit('results-preview', this.form);
      },
      deep: true,
    },
    baseForm: {
      handler() {
        const keys = Object.keys(this.baseForm);
        if (keys.length) {
          this.form = Object.freeze(this.baseForm);
        } else {
          const { uid, name } = this.profile;
          this.form.admins = [{ uid, name }];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.pageEmitter.on('guest-change', this.handleSelectUser);
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    // 回显
    async getLiveInfo() {
      this.loading = true;
      try {
        const result = await getLiveInfo(this.roomId);
        if (result) {
          this.liveInfo = Object.freeze(result);
          const {
            liveTheme, liveType, preStartTime, admins, guests, introduce, posterUrl,
          } = this.liveInfo;

          this.form = {
            liveTheme, liveType, preStartTime, admins, guests, introduce, posterUrl,
          };
        }
      } catch (error) {
        // console.log(error)
      } finally {
        this.loading = false;
      }
    },
    // 选中互关用户
    handleSelectUser(val) {
      this.form.guests = val;
    },
    // 选中海报
    handlePosterUrlChange(val) {
      this.form.posterUrl = val;

      this.$refs.form.validateField('posterUrl', (valid) => {
        if (valid) return false;
      });
    },
    submitForm() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('submit-form', this.form);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./form.scss";

.desc-count {
  position: absolute;
  right: 0;
  bottom: -20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #c1c4cb;
  text-align: right;
  line-height: 18px;

  &.err-desc-count {
    color: #ec333a;
  }
}

</style>

<style lang="scss">
.live-date-popper {
  width: 350px;

  .el-picker-panel__content {
    width: 320px;
  }

  .el-date-table {
    td.current:not(.disabled) span {
      background-color: #3e74ca;
      color: #fff;
    }

    td.available:hover {
      color: #3e74ca;
    }

    td.today span {
      color: #3e74ca;
    }
  }

  .el-input__inner,
  .el-time-spinner__item.active:not(.disabled) {
    color: #333b44;
  }

  .el-time-panel__btn.confirm {
    color: #3e74ca;
  }

  .el-picker-panel__footer .el-button--mini {
    padding: 6px 25px;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"top"},_vm._l((3),function(i){return _c('div',{key:("mini-point-" + i),staticClass:"mini-point"})}),0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"img-box"},[_c('div',{staticClass:"img"},[((_vm.type === 'templateUrl' || _vm.type === 'uploadPoster')&& _vm.isShowUrl)?_c('img',{attrs:{"src":_vm.isShowUrl}}):_c('div',{staticClass:"play-btn"},[_c('img',{attrs:{"src":require("../img/视频.svg")}})])]),_c('div',{staticClass:"list-box"},[_c('ul',{staticClass:"list",class:!_vm.baseForm.liveType && 'bg-color'},[_c('li',{staticClass:"item"}),_c('li',{staticClass:"item"})]),_c('ul',{staticClass:"list-content"},_vm._l((6),function(i){return _c('li',{key:("list-item-" + i),staticClass:"item"},[_c('div',{staticClass:"logo pre-tooltip",class:{
                'round': !(_vm.projects),
                'party': _vm.projects,
                'deep-color': _vm.isListDeep && i === 1,
              }},[(_vm.isListDeep && (_vm.type === 'guests' || _vm.type === 'invites') && i === 1)?[_c('div',{staticClass:"tooltip"}),_c('div',{staticClass:"tooltip-box guest"},[_c('logo-panel',{staticClass:"avatar",attrs:{"info":_vm.cardInfoData}},[_c('img',{staticClass:"weitu",attrs:{"slot":"logo","src":require("../img/位图.png")},slot:"logo"})]),_c('span',[_vm._v("嘉宾姓名")])],1)]:_vm._e()],2),_c('div',{staticClass:"name pre-tooltip",class:{
                'deep-color': _vm.isListDeep && i === 1,
              }},[(_vm.isListDeep && (_vm.projects) && i === 1)?[_c('div',{staticClass:"tooltip"}),_vm._m(0,true)]:_vm._e()],2)])}),0)])]),_c('div',{staticClass:"message-box"},[_c('div',{staticClass:"msg-1"}),_c('div',{staticClass:"msg-2 pre-tooltip",class:{
          'deep-color': _vm.type === 'preStartTime',
        }},[(_vm.type === 'preStartTime')?[_c('div',{staticClass:"tooltip"}),_c('div',{staticClass:"tooltip-box time"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.baseForm.preStartTime))+"开播")])])]:_vm._e()],2)]),_c('div',{staticClass:"guest-box"},[_c('div',{staticClass:"box-title"}),_c('div',{staticClass:"box-content"},_vm._l((3),function(i){return _c('div',{key:("guest-" + i),staticClass:"rectangle-box"},_vm._l((4),function(j){return _c('div',{key:("guest-item-" + j),staticClass:"rectangle"})}),0)}),0)]),_c('div',{staticClass:"focus-box"},[_c('div',{staticClass:"box-title"}),_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"round-box"},_vm._l((10),function(j){return _c('div',{key:("focus-item-" + j),staticClass:"round-item"},[(j <= 6)?_c('div',{staticClass:"round"}):_vm._e()])}),0)])]),_c('div',{staticClass:"audience-box"},[_c('div',{staticClass:"box-title"}),_c('div',{staticClass:"box-content"},_vm._l((3),function(i){return _c('div',{key:("audience-" + i),staticClass:"round-box"},_vm._l((10),function(j){return _c('div',{key:("audience-item-" + j),staticClass:"round-item"},[(i < 3 || j < 4)?_c('div',{staticClass:"round"}):_vm._e()])}),0)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tooltip-box project"},[_c('div',{staticClass:"project-img"},[_c('img',{attrs:{"src":require("../img/logo.png")}})]),_c('div',{staticClass:"project-info"},[_c('div',{staticClass:"project-name"},[_vm._v(" 项目名称 ")]),_c('div',{staticClass:"project-type"},[_vm._v(" 项目简介 ")]),_c('div',{staticClass:"tag-content"},[_vm._v(" #项目标签 ")])])])}]

export { render, staticRenderFns }